
import { defineComponent, ref } from '@vue/composition-api'

import NavigationDrawer from './navigation-drawer.vue'
import AppBar from './app-bar.vue'

export default defineComponent({
  name: 'default-layout',
  components: { NavigationDrawer, AppBar },
  setup() {
    return {
      isNavOpen: ref(true),
    }
  },
})
