
import { computed, defineComponent } from '@vue/composition-api'

import { useAuthGetters } from '@/store'
import { User } from '@/api/users'
import { TranslateResult } from 'vue-i18n'

import {
  mdiAccountTie,
  mdiAccount,
  mdiTranslate,
  mdiShieldAccount,
  mdiAccountGroup,
  mdiFolderClock,
  mdiFolderMultiple,
  mdiAccountSearch,
  mdiNotebook,
  mdiTimelapse,
  mdiReceipt,
  mdiAccountClock,
  mdiFileDocument,
  mdiAccountGroupOutline,
} from '@mdi/js'
import { Rights } from '@/api/rights'
import { usePermissions } from '@/composables/permissions'

interface NavItem {
  title: TranslateResult
  icon?: string
  to: string
  auth?: boolean
  right?: string | string[]
  children?: NavItem[]
  gate?: boolean
}

export default defineComponent({
  name: 'navigation-drawer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const APP_NAME = computed(() => process.env.VUE_APP_NAME)

    const isOpen = computed({
      get: () => props.value,
      set: (val: boolean) => {
        emit('input', val)
      },
    })

    const permissions = usePermissions()

    const currentUser = computed((): User | null => root.$store.state.auth.currentUser)

    const { isAuthenticated, userRights } = useAuthGetters()

    const navItems = computed((): NavItem[] => [
      {
        title: root.$t('timetracking.title'),
        to: '/timetracking',
        icon: mdiTimelapse,
        auth: true,
      },
      {
        title: root.$tc('document.title', 2),
        to: '/documents',
        icon: mdiFileDocument,
        auth: true,
        right: [Rights.USER_DOCUMENT_READ_OWN, Rights.USER_DOCUMENT_READ_ALL],
      },
      {
        title: root.$t('reporting.title'),
        to: '/reporting',
        icon: mdiFileDocument,
        auth: true,
        children: [
          {
            title: root.$t('reporting.summary'),
            to: '/reporting/summary',
            icon: mdiFileDocument,
            auth: true,
          },
          {
            title: root.$t('reporting.monthly'),
            to: '/reporting/monthly',
            icon: mdiFileDocument,
            auth: true,
          },
        ],
      },
      {
        title: root.$t('admin.title'),
        to: '/admin',
        icon: mdiAccountTie,
        auth: true,
        right: 'USER_READ_INTERNALS',
        children: [
          {
            title: root.$t('admin.users.title'),
            to: '/admin/users',
            icon: mdiAccount,
            auth: true,
            right: 'USER_READ_INTERNALS',
          },
          {
            title: root.$t('admin.translations.title'),
            to: '/admin/translations',
            icon: mdiTranslate,
            auth: true,
            right: 'TRANSLATION_READ',
          },
          {
            title: root.$t('admin.roles.title'),
            to: '/admin/roles',
            icon: mdiAccountGroup,
            auth: true,
            right: 'ROLE_READ',
          },
          {
            title: root.$t('admin.rights.title'),
            to: '/admin/rights',
            icon: mdiShieldAccount,
            auth: true,
            right: 'RIGHT_READ',
          },
          {
            title: root.$t('admin.manageHours.title'),
            to: '/admin/manage-hours',
            icon: mdiAccountClock,
            auth: true,
            right: 'MONTHLY_HOURS_READ_ALL',
          },
          {
            title: root.$t('admin.documents.title'),
            to: '/admin/documents',
            icon: mdiFileDocument,
            auth: true,
            right: 'USER_DOCUMENT_READ_ALL',
          },
          {
            title: root.$tc('team.title', 2),
            to: '/admin/teams',
            icon: mdiAccountGroupOutline,
            auth: true,
            right: Rights.TEAM_READ,
          },
        ],
      },
      {
        title: root.$tc('project.title', 2),
        to: '/project',
        icon: mdiFolderMultiple,
        auth: true,
        children: [
          {
            title: root.$tc('client.title', 2),
            to: '/project/clients',
            icon: mdiAccountSearch,
            right: 'CLIENT_READ',
          },
          {
            title: root.$tc('invoice.title', 2),
            to: '/project/invoices',
            icon: mdiReceipt,
            auth: true,
            gate: permissions.canReadInvoices.value,
          },
          {
            title: root.$tc('plan.title', 2),
            to: '/project/plans',
            icon: mdiNotebook,
            auth: true,
          },
          {
            title: root.$tc('project.title', 2),
            to: '/project/projects',
            icon: mdiFolderClock,
            right: 'PROJECT_READ',
          },
          {
            title: root.$tc('order.title', 2),
            to: '/project/orders',
            icon: mdiFolderClock,
            right: Rights.ORDER_READ_ALL,
            gate: permissions.canReadOrder.value,
          },
          {
            title: root.$tc('activity.title', 2),
            to: '/project/activities',
            icon: mdiFolderClock,
          },
        ],
      },
    ])

    const navItemFilter = (item: NavItem): boolean => {
      if (isAuthenticated) {
        if (typeof item.gate !== 'undefined') return item.gate
        if (Array.isArray(item.right)) return item.right.some((v) => userRights.value.includes(v))
        if (item.right) return userRights.value.includes(item.right)
        return true
      }

      return !item.auth
    }

    const filteredNavItems = computed(() =>
      navItems.value
        .filter((item) => navItemFilter(item))
        .map((item) => {
          return {
            ...item,
            children: item.children?.filter((child) => navItemFilter(child)),
          }
        })
    )

    return {
      APP_NAME,
      isOpen,
      currentUser,
      filteredNavItems,
    }
  },
})
